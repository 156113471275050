import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavLink } from 'react-bootstrap';
import { FaFacebook, FaYoutube, FaTiktok, FaInstagram } from 'react-icons/fa';
import { ReactComponent as BetsOffLogo } from '../assets/images/bets-off-logo.svg';
import { ReactComponent as xLogo } from '../assets/images/x-logo.svg';

const icons = {
    betsoff: BetsOffLogo,
    facebook: FaFacebook,
    twitter: xLogo, // TODO: Remove this line once CMS values are all changed from `twitter` to `x`.
    x: xLogo,
    youtube: FaYoutube,
    tiktok: FaTiktok,
    instagram: FaInstagram,
};

const SocialIcon = (props) => {
    const SpecificIcon = icons[props.identifier];
    return <SpecificIcon />;
};

const SocialMenu = (props) => {
    if (props.menu_json && props.menu_json.length) {
        return (
            <Nav as='ul' className={props.className}>
                {props.menu_json.map((node, key) => {
                    return (
                        <li key={`social-menu-item-${key}`}>
                            <NavLink href={node.webLinkUrl} className='nav-link' target='_blank' rel='noopener noreferrer' aria-label={node.label}>
                                <SocialIcon identifier={node.socialSiteIdentifier} />
                            </NavLink>
                        </li>
                    );
                })}
            </Nav>
        );
    } else {
        return null;
    }
};

SocialMenu.propTypes = {
    menu_json: PropTypes.array,
    className: PropTypes.string,
};

export default SocialMenu;
